import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'
import TimerRoundedIcon from '@mui/icons-material/TimerRounded'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'

import Profiles from 'data/Profile'
import ChildProfileData, { ChildProfile } from 'data/ChildProfile'

import {
  useGetMyLegacyProfileQuery,
  useGetRecordByIdQuery,
} from 'store/api/index'
import { Record, RecordType } from 'store/api/types/Record.types'

import BackButton from 'components/BackButton'
import Button from 'components/Button'
import ImageGallery from 'components/ImageGallery'
import { LikeButton } from 'scenes/BrowseRecords/components/RecordList/RecordCard/LikeButton'
import Loader from 'components/Loader'
import SubTitle from 'components/SubTitle'
import Title from 'components/Title'
import Thumbnails from 'components/Thumbnails'
import BreakRecordRestriction from 'components/BreakRecordRestriction'
import Conditional from 'components/Conditional'
import { PageWrapper } from 'components/PageWrapper'

import { RecommendationArea } from './RecommendationArea'
import { ShareLink } from './ShareLink'

import { sortLinksImagesFirst } from 'utils/url'

import './record-details.css'
import styles from './RecordDetails.module.scss'

type RecordDetailParams = {
  id: string
}

const RecordDetails: React.FC = () => {
  const { id = '' } = useParams<RecordDetailParams>()
  const { data: profile } = useGetMyLegacyProfileQuery()
  const navigate = useNavigate()

  const { data: record, isLoading } = useGetRecordByIdQuery(id, { skip: !id })

  const [isRestricted, setIsRestricted] = useState(false)

  let age =
    new Date(
      new Date().getTime() - new Date(profile?.dateOfBirth || 0).getTime(),
    ).getFullYear() - 1970

  const ageBracket = record
    ? record.ageGap.split('-').map((i) => parseInt(i))
    : []

  const handleBreakRecord = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    record: Record,
  ) => {
    e.preventDefault()
    if (
      age >= ageBracket[0] &&
      age <= ageBracket[1] &&
      (profile as ChildProfile)?.gender === record.gender.toLowerCase()
    ) {
      navigate(
        record.quizId
          ? `/quiz/${record.quizId}`
          : `/break-a-record/${record.id}`,
      )
    } else if (Profiles.isParentProfile(profile) && record.type === 'GROUP') {
      navigate(`/group-submission/${record.id}`)
    } else {
      setIsRestricted(true)
    }
  }

  const [imageIndex, setImageIndex] = useState<number>(0)

  const isAdminLogged = profile && Profiles.isAdminProfile(profile)

  const getRecordInfoDescription = (record: Record) => {
    const gender = ChildProfileData.getGender(record.gender)
    const address = `${record.location.city}, ${
      record.location.state === 'N/A' ? '' : record.location.state
    } (${record.location.country})`

    const recordOwnerInfo =
      record.type === RecordType.SINGLE
        ? `${gender} - ${record.ageGap} YEARS OLD`
        : `${record.category} GROUP CATEGORY`
    return `${recordOwnerInfo} - ${address}`.toUpperCase()
  }

  return (
    <PageWrapper>
      <div
        className="w-100 h-100 center pv3 ph3 ph5-m ph5-l"
        style={{ maxWidth: '74.4rem' }}
      >
        <BreakRecordRestriction
          isOpen={isRestricted}
          onClose={() => setIsRestricted(false)}
          record={record}
        />
        <Loader
          active={isLoading}
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
          className="z-999"
        />
        {!isLoading && record && (
          <div style={{ backgroundColor: 'white', padding: '1rem' }}>
            <div className="w-100 pb3 flex flex-start">
              <BackButton backUrl="/browse-records">
                Back to Browse Records
              </BackButton>
            </div>
            <div className={styles.container} style={{ maxWidth: '67rem' }}>
              <ImageGallery
                alt="Image representing the record"
                imageLinks={sortLinksImagesFirst(record.mediaLinks)}
                initialIndex={imageIndex}
                onChangeIndex={setImageIndex}
              />
              <div className="flex flex-column pt3 pt0-l">
                <div className="flex justify-between w-100">
                  {/*{record?.newRecordId ? (*/}
                  {/*  <Link*/}
                  {/*    to={`/records/${record?.newRecordId}`}*/}
                  {/*    target="_blank"*/}
                  {/*    className="break_record_link"*/}
                  {/*  >*/}
                  {/*    Broken! click here to see new record*/}
                  {/*  </Link>*/}
                  {/*) : (*/}
                  <Button
                    // TODO: BE should return Status of record which help us disable it
                    disabled={record.isBroken}
                    className="record-details__record-button"
                    onClick={(e) => handleBreakRecord(e, record)}
                    endIcon={
                      <img
                        src="/assets/trophy.svg"
                        alt="a trophy representing the record to be claimed"
                      />
                    }
                  >
                    <p className="ma0" style={{ paddingRight: '10px' }}>
                      {record.isBroken ? 'Record Broken' : 'BREAK THIS RECORD'}
                    </p>
                  </Button>
                  {/*)}*/}
                  {!isAdminLogged && (
                    <LikeButton
                      isLiked={record.isLiked}
                      recordId={record.id}
                      likeAmount={record.likes}
                    />
                  )}
                </div>
                <span className="record-details__category pt3 pb2 mb0 dark-gray">
                  {getRecordInfoDescription(record)}
                </span>
                <Title className="nunito-extra-bold indigo-blue f3 pr3">
                  {record.idea.title}
                </Title>
                <p className={styles.text}>{record.description}</p>
                {record.mediaLinks.length > 1 && (
                  <Thumbnails
                    className="pt3 pt4-l"
                    imageLinks={sortLinksImagesFirst(record.mediaLinks)}
                    selectedIndex={imageIndex}
                    onClick={setImageIndex}
                  />
                )}
              </div>
            </div>
            <div className={styles.container} style={{ maxWidth: '67rem' }}>
              <ShareLink imageUrl={record.previewImageUrl} />
              <div>
                <Conditional shouldRender={!!record?.notes}>
                  <SubTitle className="neo-red pt3 pt4-l">
                    Record remarks
                  </SubTitle>
                  <p className={styles.text}>{record?.notes}</p>
                </Conditional>
                <Conditional
                  shouldRender={!!record?.requireParentalSupervision}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <ReportProblemRoundedIcon
                      style={{ color: 'var(--neo-red)' }}
                    />
                    <p className={styles.informationText}>
                      This record require proof of parental supervision.
                    </p>
                  </div>
                </Conditional>
                <Conditional shouldRender={!!record?.requireTimingDevice}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <TimerRoundedIcon style={{ color: 'var(--neo-red)' }} />
                    <p className={styles.informationText}>
                      A timing device must be visible in video submitted
                    </p>
                  </div>
                </Conditional>
                <Conditional shouldRender={!!record?.noNewRecord}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <BlockRoundedIcon style={{ color: 'var(--neo-red)' }} />
                    <p className={styles.informationText}>
                      We are not accepting new submissions for this record.
                    </p>
                  </div>
                </Conditional>
              </div>
            </div>
            <div className={styles.container} style={{ maxWidth: '67rem' }}>
              <RecommendationArea recordId={record.id} />
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  )
}

export default RecordDetails
