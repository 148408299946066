import React, { FC, useState } from 'react'
import { Edit, Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import Conditional from 'components/Conditional'

import Profile from 'data/Profile'
import {
  useDeleteRecommendationMutation,
  useGetAuthProfileQuery,
  useGetRecommendationByIdQuery,
} from 'store/api'

import { RecommendationEdit } from './components/RecommendationEdit'
import { RecommendationView } from './components/RecommendationView'
import styles from './RecommendationArea.module.scss'

type RecommendationAreaProps = {
  recordId: string
}
export const RecommendationArea: FC<RecommendationAreaProps> = ({
  recordId,
}) => {
  const { data: recommendation } = useGetRecommendationByIdQuery(recordId)
  const { data: loggedProfile } = useGetAuthProfileQuery()
  const [deleteRecommendation] = useDeleteRecommendationMutation()

  const [showEdit, setShowEdit] = useState(false)

  const isAdmin = Profile.isAdminProfileV2(loggedProfile)

  return (
    <div className={styles.main}>
      <Conditional shouldRender={isAdmin}>
        <div className={styles.adminButtons}>
          <IconButton
            aria-label="Edit"
            component="span"
            onClick={() => setShowEdit(!showEdit)}
          >
            <Edit />
          </IconButton>
          <Conditional shouldRender={!!recommendation}>
            <IconButton
              aria-label="Edit"
              component="span"
              onClick={() => deleteRecommendation(recordId)}
            >
              <Delete />
            </IconButton>
          </Conditional>
        </div>
      </Conditional>
      <Conditional shouldRender={showEdit}>
        <RecommendationEdit
          recordId={recordId}
          onChange={() => setShowEdit(false)}
        />
      </Conditional>
      {recommendation && !showEdit && (
        <RecommendationView recommendation={recommendation} />
      )}
    </div>
  )
}
