import React from 'react'
import { Navigate } from 'react-router'

import { useGetAuthProfileQuery } from 'store/api'

import Profiles from 'data/Profile'

const DefaultRedirect: React.FC = () => {
  const { data: profile, isSuccess } = useGetAuthProfileQuery()
  const isAdminLogged = profile && Profiles.isAdminProfileV2(profile)

  return (
    <>
      {isSuccess && isAdminLogged ? (
        <Navigate to="/admin/submissions" />
      ) : (
        <Navigate to="/" />
      )}
    </>
  )
}

export default DefaultRedirect
