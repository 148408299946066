import React, { FC } from 'react'
import { Interweave } from 'interweave'

import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import { Page } from 'components/Page'
import {
  useGetAuthProfileQuery,
  useGetCmsRecordByContentNameQuery,
} from 'store/api'
import Conditional from 'components/Conditional'
import Loader from 'components/Loader'
import Profiles from '../../data/Profile'
import { AdminCKEditorPencil, CmsEditForm } from 'components/AdminCKEditor'
import { CmsDataToUpdate } from '../../store/api/types/Record.types'
import useToggle from 'hooks/useToggle'
import styles from 'components/AdminCKEditor/AdminCKEditor.module.scss'

const CMS_PAGE_NAME = 'privacy_policy'

export const PrivacyPolicy: FC = () => {
  const { data: profile, isSuccess } = useGetAuthProfileQuery()
  const isAdminLogged =
    isSuccess && Boolean(profile) && Profiles.isAdminProfileV2(profile)
  const [isEditing, ClickEditToggle] = useToggle()
  const {
    data: contentData,
    isLoading,
    isUninitialized,
  } = useGetCmsRecordByContentNameQuery(CMS_PAGE_NAME)

  const shouldDisplayContent = !isEditing && !isLoading && !isUninitialized
  const isEditMode = Boolean(contentData) && isAdminLogged && isEditing

  return (
    <Page>
      <BorderedContainer>
        <Loader
          active={isLoading}
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
          className="z-999"
        />
        <Title>
          Privacy Policy <AdminCKEditorPencil onClick={ClickEditToggle} />
        </Title>
        <Conditional shouldRender={isEditMode}>
          <CmsEditForm contentData={contentData as CmsDataToUpdate} />
        </Conditional>
        <Conditional shouldRender={shouldDisplayContent}>
          <Interweave
            content={contentData?.content}
            className={styles.contentSpan}
          />
        </Conditional>
      </BorderedContainer>
    </Page>
  )
}
