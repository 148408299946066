import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import cn from 'classnames'

import { showToaster } from 'store/app/actions'

import Records from 'data/Record'
import {
  useGetChildRecordByIdQuery,
  useLazyGetRecordCertificateQuery,
} from 'store/api'
import { RecordStatus, Submission } from 'store/api/types/Record.types'

import BackButton from 'components/BackButton'
import Button from 'components/Button'
import ImageGallery from 'components/ImageGallery'
import LinkButton from 'components/LinkButton'
import Loader from 'components/Loader'
import RejectReasonSection from 'components/RejectReasonSection'
import Title from 'components/Title'
import Thumbnails from 'components/Thumbnails'
import { getGenderLabel } from 'components/RecordSubmissionTable/RecordSubmissionTable'

import { capitalizeAndRemoveUnderscode } from 'utils/strings'
import { sortLinksImagesFirst } from 'utils/url'

import './details.css'
import Conditional from 'components/Conditional'
import { getSubmissionStatusColor } from './index'
import { PageWrapper } from 'components/PageWrapper'
import QRCode from 'qrcode.react'
import { CONFIG } from 'config'

type RecordSubmissionDetailsParams = {
  id: string
}

const RecordSubmissionDetails: React.FC = () => {
  const { id = '' } = useParams<RecordSubmissionDetailsParams>()

  const { data: submission, isLoading } = useGetChildRecordByIdQuery(id, {
    skip: !id,
  })
  const [getCertificate, { isLoading: downloadingCertificate }] =
    useLazyGetRecordCertificateQuery()

  const dispatch = useDispatch()

  const [imageIndex, setImageIndex] = useState<number>(0)

  const handleDownload = async (recordSubmission: Submission) => {
    try {
      const certificateLink = await getCertificate(recordSubmission.id).unwrap()
      const anchorElement = document.createElement('a')
      anchorElement.href = certificateLink
      anchorElement.download = `KWR - ${recordSubmission.idea} Certificate.pdf`
      anchorElement.click()
    } catch (e) {
      dispatch(
        showToaster({
          message: 'Error downloading certificate.',
          color: 'red',
        }),
      )
    }
  }

  const getInfoDescription = (submission: Submission) => {
    const parsedGender = getGenderLabel(submission.gender)
    const address = Records.getAddressForRecordCard(submission.location)
    const baseInfoDescription =
      submission.type === 'GROUP'
        ? `${submission.category} GROUP RECORD`
        : `${parsedGender} - ${submission.ageGap} YEARS OLD`
    return `${baseInfoDescription} - ${address}`.toUpperCase()
  }
  const userAgent = navigator.userAgent
  const isIphone = /iPhone/i.test(userAgent)
  const isChildRecord = submission?.type === 'SINGLE'
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  console.log(isChildRecord)

  return (
    <PageWrapper>
      <div
        className="w-100 h-100 center pv3 ph3 ph5-m ph5-l"
        style={{ maxWidth: '74.4rem' }}
      >
        <Loader
          active={isLoading}
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
          className="z-999"
        />
        {!isLoading && submission && (
          <>
            <div className="w-100 pb3 flex justify-between items-center mw8">
              <div className="my-record-details__column-left flex justify-start">
                <BackButton backUrl="/my-records">
                  Back to My Records
                </BackButton>
              </div>
            </div>
            <div className="flex flex-column flex-row-l justify-between-ns bg-white w-100 mw8">
              <div className="my-record-details__column-left">
                <ImageGallery
                  alt="Image representing the record"
                  imageLinks={sortLinksImagesFirst(submission.mediaLinks)}
                  initialIndex={imageIndex}
                  onChangeIndex={setImageIndex}
                />
              </div>
              <div className="my-record-details__column-right flex flex-column-l flex-column-reverse items-start-l pb3">
                <div className="flex flex-column pt2 pt0-l">
                  <div className="my-record-details__column-right">
                    <h2
                      className={cn(
                        'my-record-details__column-status ma0 nunito-regular ',
                        getSubmissionStatusColor(submission.status),
                      )}
                    >
                      {capitalizeAndRemoveUnderscode(submission.status)}
                    </h2>
                  </div>
                  <span className="my-record-details__category pt3 pt0-l pb2 mb0 dark-gray">
                    {getInfoDescription(submission)}
                  </span>
                  <Title className="nunito-extra-bold indigo-blue f3 pr3">
                    {submission.idea}
                  </Title>
                  <p className="dark-gray ma0 mb3 mr2 pt3 f6 f5-l lh-title">
                    {submission.description}
                  </p>
                  {submission.status !== RecordStatus.IN_REVIEW && (
                    <div className="my-records-details__buttons flex flex-column justify-center pv2 pv3-l justify-end-m justify-end-l">
                      {submission.status === RecordStatus.APPROVED ? (
                        <>
                          <LinkButton className="pb4" to="/shop">
                            Shop Book / Certificate
                          </LinkButton>
                          {CONFIG.FEATURE_552 && isChildRecord ? (
                            isIphone ? (
                              <Fragment>
                                <p className="dark-gray ma0 mb3 mr2 pt3 f6 f5-l lh-title">
                                  You can download Your Apple wallet pass.
                                </p>
                                <a
                                  href={`/api/getApplePass/${id}`}
                                  download="your-pass.pkpass"
                                  className="qr-code-apple-pass"
                                >
                                  <img
                                    src={`/assets/walletPass/US-UK_Add_to_Apple_Wallet_RGB_101421.svg`}
                                    alt="US-UK_Add_to_Apple_Wallet_RGB"
                                    className="Add_to_Apple_Wallet_RGB"
                                  />
                                </a>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <p className="dark-gray ma0 mb3 mr2 pt3 f6 f5-l lh-title">
                                  To get Your Apple wallet pass scan with
                                  QR-code or open this page on your iPhone and
                                  download it.
                                </p>
                                <div className="qr-code-apple-pass">
                                  <img
                                    src={`/assets/walletPass/US-UK_Add_to_Apple_Wallet_RGB_101421.svg`}
                                    alt="US-UK_Add_to_Apple_Wallet_RGB"
                                    className="Add_to_Apple_Wallet_RGB"
                                  />
                                  <QRCode
                                    value={`${baseUrl}/api/getApplePass/${id}`}
                                    size={150}
                                    bgColor="#FFFFFF"
                                    fgColor="#000000"
                                  />
                                </div>
                              </Fragment>
                            )
                          ) : null}
                          <Conditional
                            shouldRender={
                              submission?.location?.country?.toLowerCase() ===
                              'in'
                            }
                          >
                            {!downloadingCertificate ? (
                              <Button
                                className="w-100"
                                ghost
                                onClick={() => handleDownload(submission)}
                                outline
                                theme="primary"
                                disabled={downloadingCertificate}
                              >
                                Download Certificate
                              </Button>
                            ) : (
                              <Loader
                                active
                                style={{
                                  width: '45px',
                                  height: '45px',
                                  left: 'calc(50% - 30px)',
                                  position: 'relative',
                                }}
                              />
                            )}
                          </Conditional>
                        </>
                      ) : (
                        <>
                          <RejectReasonSection
                            message={submission.rejectReason || null}
                          />
                          <LinkButton
                            to={`/break-a-record/${submission.id}`}
                            className="w-100"
                            theme="primary"
                          >
                            Try To Break Again
                          </LinkButton>
                        </>
                      )}
                    </div>
                  )}
                </div>
                {submission.mediaLinks.length > 1 && (
                  <Thumbnails
                    className="pt3"
                    imageLinks={sortLinksImagesFirst(submission.mediaLinks)}
                    selectedIndex={imageIndex}
                    onClick={setImageIndex}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  )
}

export default RecordSubmissionDetails
