import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import { useGetAuthProfileQuery, useGetProductByIdQuery } from 'store/api'
import Profiles from 'data/Profile'

import Loader from 'components/Loader'
import BackButton from 'components/BackButton'
import ImageWithPlaceHolder from 'components/ImageWithPlaceholder'
import { Page } from 'components/Page'
import EmptySection from 'components/EmptySection'
import Conditional from 'components/Conditional'

import { BuyProductForm } from './components/BuyProductForm'

import styles from './ProductDetails.module.scss'
import { AccountRole } from 'store/api/types/Auth.types'

type ProductDetailsParams = {
  id: string
}

type ProductDetailsProps = {
  childrenApprovedRecords: {
    role: AccountRole
    childName: string
    records: {
      id: string
      title: string
      url: string
    }[]
  }[]
}

export const ProductDetails: FC<ProductDetailsProps> = ({
  childrenApprovedRecords,
}) => {
  const { id } = useParams<ProductDetailsParams>()
  const { data: product, isLoading: isFetching } = useGetProductByIdQuery(
    `gid://shopify/Product/${id}`,
  )

  const { data: loggedProfile, isLoading } = useGetAuthProfileQuery()

  const shouldRender = !isLoading && !isFetching
  const showForm = !(
    product?.isCustom &&
    loggedProfile &&
    Profiles.isChildProfileV2(loggedProfile) &&
    !childrenApprovedRecords[0]?.records?.length
  )

  return (
    <Page>
      <Loader active={!shouldRender} className={styles.loader} />
      <Conditional shouldRender={shouldRender}>
        <div className={styles.backButton}>
          <BackButton backUrl="/shop">Back to KWR Store home</BackButton>
        </div>
        {product && (
          <div className={styles.main}>
            <ImageWithPlaceHolder
              src={product.previewImageUrl}
              alt={`Image representing the ${product.title}  product`}
              className="product-details__media"
              ariaHidden={true}
            />
            <Conditional shouldRender={!showForm}>
              <EmptySection message="You must break a record to buy this product." />
            </Conditional>
            <Conditional shouldRender={showForm}>
              <BuyProductForm
                product={product}
                childrenApprovedRecords={childrenApprovedRecords}
                isCustomProduct={product.isCustom}
              />
            </Conditional>
          </div>
        )}
      </Conditional>
    </Page>
  )
}
