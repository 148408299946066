import { useState, useCallback, useEffect } from 'react'
import { CONFIG } from '../config'
import axios from 'axios'
import {
  Record,
  PublicRecordFilters,
  Category,
  GroupCategory,
  RecordType,
} from 'store/api/types/Record.types'
import { removeDuplicatesById } from '../utils/arrays'
import { useLazyGetPublicRecordsQuery } from 'store/api'
import { GenderV2 } from '../store/api/types/Gender.types'
import { Location } from '../data/Location'
import { QueryStatus } from '@reduxjs/toolkit/query'

const doRequest = async (
  params: any,
  limit: number,
  offset: number,
): Promise<Record[]> => {
  const { data } = await axios.get(`${CONFIG.FUNCTION_URL}/records`, {
    params: {
      take: limit,
      skip: offset,
      search: params.searchString,
      category: params.category,
      ageGaps: params.filters.ages,
      genders: params.filters.genders.map((gender: any) =>
        gender.toUpperCase(),
      ),
    },
  })
  return data
}

export const useRecordApiHook = (search: any, limit: number) => {
  const [data, setData] = useState<Record[]>([])
  const [loading, setLoading] = useState(false)
  const [offset, setOffset] = useState(limit)
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    setData([])
    getRecords().catch()
    // eslint-disable-next-line
  }, [search])

  const getRecords = async () => {
    try {
      setLoading(true)
      const records = await doRequest(search, limit, 0)
      setData(records)
      if (records.length === 0) {
        setIsEnd(true)
      } else {
        setOffset(limit)
        setIsEnd(false)
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const next = useCallback(async () => {
    if (isEnd) return
    try {
      setLoading(true)
      const records = await doRequest(search, limit, offset)
      if (records.length === 0) {
        setIsEnd(true)
      } else {
        setOffset(offset + limit)
        const arr = removeDuplicatesById<Record>([...data, ...records])
        setData(arr)
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }, [search, offset, data, isEnd, limit])

  return {
    loading,
    data,
    next,
  }
}

const PAGE_SIZE = 12

export interface PublicRecord {
  id: string
  previewImageSrc: string
  ambassadorLvl: number | null
  gender: GenderV2
  ageGap: string
  quizId?: string
  location: Location
  isBroken: boolean
  idea: string
  description: string
  isLiked: boolean
  likeAmount: number
  priority: 0 | 1
  type: RecordType
  category: Category | GroupCategory
  isFirstRecord: boolean
  age: number
}

//todo: create domain to return this data from BE
const convertToPublicRecord = (records: Record[]): PublicRecord[] => {
  return records.map((record) => ({
    id: record.id,
    quizId: record.quizId,
    previewImageSrc: record.previewImageUrl,
    ambassadorLvl: record.ambassadorLevel,
    gender: record.gender.toUpperCase() as GenderV2,
    ageGap: record.ageGap,
    isBroken: record.isBroken,
    location: record.location,
    idea: record.idea.title,
    description: record.description,
    isLiked: record.isLiked,
    likeAmount: record.likes,
    priority: record.priority,
    type: record.type,
    category: record.category,
    isFirstRecord: record.isFirstRecord,
    age: record.age,
  }))
}

export const usePublicRecords = (filters: PublicRecordFilters) => {
  const [skip, setSkip] = useState(0)
  const [hasNextPage, setHasNextPage] = useState<boolean>(true)
  const [filtersForQuery, setFiltersForQuery] = useState({
    ...filters,
    skip,
    take: PAGE_SIZE,
  })
  const [records, setRecords] = useState<PublicRecord[]>([])

  const [trigger, { data = [], isLoading, error, status }] =
    useLazyGetPublicRecordsQuery()

  useEffect(() => {
    trigger(filtersForQuery)
  }, [filtersForQuery, trigger])

  useEffect(() => {
    setRecords([])
    setSkip(0)
    setFiltersForQuery({
      ...filters,
      skip: 0,
      take: PAGE_SIZE,
    })
  }, [filters])

  useEffect(() => {
    if (data.length) {
      setHasNextPage(data.length === PAGE_SIZE)
      setRecords((records) => [...records, ...convertToPublicRecord(data)])
      setSkip((skip) => skip + PAGE_SIZE)
    }
  }, [data])

  const onNext = useCallback(() => {
    setSkip((skip) => {
      setFiltersForQuery((filters) => ({ ...filters, skip }))
      return skip
    })
  }, [])

  return {
    isLoading: isLoading || status === QueryStatus.pending || Boolean(error),
    records,
    onNext,
    hasNextPage,
  }
}
