import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'
import dayjs from 'dayjs'

import BorderedContainer from 'components/BorderedContainer'
import Disclaimer from 'components/Disclaimer'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import LinkButton from 'components/LinkButton'
import SubmitButton from 'components/SubmitButton'
import Conditional from 'components/Conditional'
import Title from 'components/Title'
import { Page } from 'components/Page'
import { SignUpInfo } from 'components/SignUpInfo'

import SignUpProfileDatas, { SignUpProfile } from 'typings/SignUpProfile'

import { updateSignUpProfile } from 'store/signup/actions'

import { HowDidYouFoundUs } from '../../store/api/types/Event.types'
import {
  useGetAuthProfileQuery,
  useVerifySignUpEmailMutation,
} from '../../store/api'
import { showToaster } from '../../store/app/actions'

import { useForm } from 'react-hook-form'
import { FormCheckbox } from 'components/Form/FormCheckbox'
import { FormInput } from 'components/Form/FormInput'

import styles from './SignUp.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import { buildDefaultValue, SIGN_UP_VALIDATOR } from './SignUp.utils'
import { FormSelect } from 'components/Form/FormSelect'
import { FormDatePicker } from 'components/Form/FormDatePicker'
import { CONFIG } from 'config'

const HOW_DID_YOU_FOUND_US_ITEMS = [
  {
    label: 'Recommended by a friend',
    value: HowDidYouFoundUs.RECOMMENDED_BY_A_FRIEND,
  },
  { label: 'Search engine', value: HowDidYouFoundUs.SEARCH_ENGIN },
  { label: 'Facebook', value: HowDidYouFoundUs.FACEBOOK },
  { label: 'Instagram', value: HowDidYouFoundUs.INSTAGRAM },
  { label: 'YouTube', value: HowDidYouFoundUs.YOUTUBE },
  {
    label: 'Internet advertising',
    value: HowDidYouFoundUs.INTERNET_ADVERTISING,
  },
  { label: 'Read an article', value: HowDidYouFoundUs.READ_AN_ARTICLE },
]

export const SignUp: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [verifyEmail, { isLoading }] = useVerifySignUpEmailMutation()

  const { data: profile } = useGetAuthProfileQuery()

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<SignUpProfile>({
    resolver: joiResolver(SIGN_UP_VALIDATOR),
    mode: 'onSubmit',
  })

  const onSubmit = async (data: SignUpProfile) => {
    if (SignUpProfileDatas.isValid(data)) {
      try {
        await verifyEmail({ email: data.email.trim().toLowerCase() }).unwrap()
        dispatch(
          updateSignUpProfile({
            ...data,
            email: data.email.toLowerCase(),
            isTeacher: data.isTeacher,
          }),
        )
        navigate('/choose-a-pin')
      } catch (e) {
        const errorMessage = 'Email already in use'
        dispatch(
          showToaster({
            message: errorMessage,
            color: 'red',
          }),
        )
      }
    }
  }

  useEffect(() => {
    if (CONFIG.APPLY_ANALYTICS === 'true') {
      window?.fbq('trackCustom', 'SignUp')
      window.dataLayer?.push({
        event: 'pageview',
      })
    }
  }, [])

  useEffect(() => {
    reset(buildDefaultValue())
  }, [reset])

  if (profile) {
    return <Navigate to="/" />
  }

  return (
    <Page>
      <Conditional shouldRender={!profile}>
        <Fragment>
          <BorderedContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Title>Parent Registration</Title>
              <Disclaimer />
              <SignUpInfo />
              <FormCheckbox
                name={`isTeacher`}
                control={control}
                label="I am registering as an educator, trainer, organizer, etc."
                className={styles.checkbox}
              />
              <div className={styles.namesDiv}>
                <div className={styles.firstName}>
                  <FormInput
                    label="First Name"
                    name="firstName"
                    control={control}
                    errorText={errors.firstName?.message as string}
                  />
                </div>
                <div className={styles.lastName}>
                  <FormInput
                    label="Last Name"
                    name="lastName"
                    control={control}
                    errorText={errors.lastName?.message as string}
                  />
                </div>
              </div>
              <FormSelect
                name="howDidYouFoundUs"
                label="How did you find us"
                items={HOW_DID_YOU_FOUND_US_ITEMS}
                control={control}
                errorText={errors.howDidYouFoundUs?.message as string}
              />
              <FormInput
                label="Email"
                name="email"
                control={control}
                errorText={errors.email?.message as string}
              />
              <FormDatePicker
                name="dateOfBirth"
                label="Date of Birth"
                control={control}
                maxDate={dayjs().add(-25, 'years').toDate()}
                errorText={errors.dateOfBirth?.message}
                disableFuture
              />
              <div className={styles.buttonsDiv}>
                <SubmitButton
                  className={styles.nextButton}
                  disabled={isLoading}
                >
                  Next
                </SubmitButton>
                <LinkButton
                  className={styles.alreadyHaveButton}
                  ghost
                  to="/login"
                  theme="primary"
                >
                  I already have an account
                </LinkButton>
              </div>
            </form>
          </BorderedContainer>
          <ImageDecoration
            type={ImageDecorationType.GIRL_RUNNING}
            position="left"
          ></ImageDecoration>
        </Fragment>
      </Conditional>
    </Page>
  )
}
