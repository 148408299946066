import { Location } from '../../../data/Location'
import { Gender as OldGender } from '../../../data/ChildProfile'
import { PicturedPersonParams } from '../../../data/PicturedPerson'
import { GenderV2 } from './Gender.types'

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  GENDER_X = 'GENDER_X',
}

export enum Category {
  MOST = 'MOST',
  OTHER = 'OTHER',
  LARGEST = 'LARGEST',
  FASTEST = 'FASTEST',
  HIGHEST = 'HIGHEST',
  LONGEST = 'LONGEST',
  SMALLEST = 'SMALLEST',
  ENDURANCE = 'ENDURANCE',
  COLLECTIONS = 'COLLECTIONS',
}

export enum ExtraCategory {
  TOP_PICKS = 'TOP_PICKS',
  NEWEST = 'NEWEST',
  POPULAR = 'MOST_POPULAR',
  BROKEN = 'BROKEN',
}

export type CategoryToSearch = Category | ExtraCategory

export enum RecordType {
  SINGLE = 'SINGLE',
  GROUP = 'GROUP',
}

export type Record = {
  id: string
  description: string
  idea: {
    id: string
    title: string
  }
  category: Category | GroupCategory
  brokenOn: Date
  gender: OldGender
  recordOwnerId: string
  ageGap: string
  location: Location
  likes: number
  isLiked: boolean
  mediaLinks: string[]
  newRecordId: string
  priority: 0 | 1
  noNewRecord: boolean
  requireTimingDevice: boolean
  requireParentalSupervision: boolean
  notes?: string
  deletedAt: Date
  ambassadorLevel: number
  quizId?: string
  type: RecordType
  previewImageUrl: string
  isFirstRecord: boolean
  recommendationId: string
  isBroken: boolean
  age: number
}

export type RecordSubmissionParams = {
  previousRecordId?: string
  brokenOn: Date
  category: Category
  description: string
  idea: string
  location: Location
  mediaLinks: string[]
  picturedPerson: PicturedPersonParams[]
  funFacts?: string
  witnessEmails: string[]
  ambassadorId?: string
  paypalTransactionId?: string
}

export interface PublicRecordFilters {
  search: string
  category: string
  ageGaps: string[]
  genders: GenderV2[]
}

export interface PublicRecordParams extends PublicRecordFilters {
  take: number
  skip: number
}

export type ReviewRecord = {
  id: string
  status: 'APPROVE' | 'REJECT'
  rejectNotes?: string
}

export type RecordPriority = {
  recordId: string
  priority: 0 | 1
}

export enum RecordStatus {
  NEW = 'NEW',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  BROKEN = 'BROKEN',
}

export type Submission = {
  id: string
  idea: string
  category: Category
  status: RecordStatus
  priority?: number
  recordOwner: {
    firstName: string
    lastName: string
    gender: Gender
    dateOfBirth: Date
  }
  parentAccount: {
    email: string
    firstName: string
    lastName: string
  } | null
  location: Location
  picturedPersons: {
    name: string
    email: string
  }[]
  witnessEmails: string[]
  mediaLinks: string[]
  gender: Gender
  ageGap: string
  previousRecordId?: string
  description: string
  funFacts?: string
  brokenOn: string
  submittedOn: Date
  ambassadorId?: string
  notes?: string
  noNewRecord: boolean
  requireParentalSupervision: boolean
  requireTimingDevice: boolean
  rejectReason?: string
  type: RecordType
  participantAmount: number
  previewImageUrl: string
  isFirstRecord: boolean
  paypalTransactionID: string
  age: number
}

export type SubmissionUpdateBody = Pick<
  Submission,
  | 'idea'
  | 'category'
  | 'previousRecordId'
  | 'description'
  | 'notes'
  | 'requireParentalSupervision'
  | 'requireTimingDevice'
  | 'noNewRecord'
  | 'mediaLinks'
  | 'isFirstRecord'
>

export type SubmissionResponse = {
  counts: {
    totalCount: number
    inReview: number
    approved: number
    rejected: number
  }
  submissions: Submission[]
}

export type ChildSubmissionResponse = {
  APPROVED: Submission[]
  REJECTED: Submission[]
  IN_REVIEW: Submission[]
}

export type SortType = {
  name: string
  order: 'asc' | 'desc'
}

export type SubmissionFilters = {
  search: string
  status: string
  sort: SortType
  take: number
  skip: number
}

export enum GroupCategory {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  GIGANTIC = 'GIGANTIC',
}

export type SubmissionCreateBody = {
  idea: string
  gender: Gender
  previousRecordId?: string
  ageGap: string
  description: string
  notes?: string
  brokenOn: Date
  location: Location
  mediaLinks: string[]
  recordCategory: {
    category: GroupCategory
    participantAmount: number | null
  }
  age: number
}

export type GroupRecordUpdateBody = {
  idea: string
  gender: Gender
  previousRecordId?: string
  description: string
  notes?: string
  mediaLinks: string[]
  recordCategory: {
    category: GroupCategory
    participantAmount: number | null
  }
  isFirstRecord: boolean
  requireParentalSupervision: boolean
  requireTimingDevice: boolean
  noNewRecord: boolean
}

export type WorldMapRecordsAmountByCountry = {
  country: string
  count: string
}

export type WorldMapRecordsHolders = {
  currentRecordHoldersAmount: number
  totalRecordHoldersAmount: number
  ambassadorsRecordHoldersAmount: number
}

export type WorldMapRecordsAmount = {
  countryAmount: WorldMapRecordsAmountByCountry[]
  holders: WorldMapRecordsHolders
}

export type CmsData = {
  id: string
  contentName: string
  content: string
}

export type CmsDataToUpdate = Omit<CmsData, 'id'>
