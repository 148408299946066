import React, { Fragment, useEffect, useState } from 'react'
import cn from 'classnames'

import BorderedContainer from 'components/BorderedContainer'
import { SubmissionCard } from 'components/Card'
import EmptySection from 'components/EmptySection'
import Loader from 'components/Loader'
import Title from 'components/Title'
import ViewSelector from 'components/ViewSelector'
import { Page as Screen } from 'components/Page'
import Conditional from 'components/Conditional'

import Profiles from 'data/Profile'
import {
  useGetAuthProfileQuery,
  useLazyGetChildSubmissionsQuery,
  useLazyGetGroupRecordsForParentQuery,
} from 'store/api'
import { ChildSubmissionResponse } from 'store/api/types/Record.types'

import useToggle from 'hooks/useToggle'

import { ItemLayout } from 'typings'

import { capitalizeAndRemoveUnderscode } from 'utils/strings'

import './my-records.css'

export const getSubmissionStatusColor = (status: any): string => {
  switch (status) {
    case 'APPROVED':
      return 'pure-green'
    case 'REJECTED':
      return 'pure-red'
    case 'IN_REVIEW':
      return 'dark-gray'
  }
  return 'pure-red'
}

const submissionsByStatus: Array<keyof ChildSubmissionResponse> = [
  'IN_REVIEW',
  'APPROVED',
  'REJECTED',
]

const MyRecords: React.FC = () => {
  const { data: profile } = useGetAuthProfileQuery()
  const [
    getChildSubmissions,
    { data: childSubmissions, isLoading: isFetching },
  ] = useLazyGetChildSubmissionsQuery()
  const [getGroupRecords, { data: groupRecords, isLoading }] =
    useLazyGetGroupRecordsForParentQuery()

  const [submissions, setSubmissions] =
    useState<ChildSubmissionResponse | null>(null)

  useEffect(() => {
    if (profile) {
      Profiles.isChildProfileV2(profile)
        ? getChildSubmissions(profile.id)
        : getGroupRecords(profile.id)
    }
  }, [profile, getChildSubmissions, getGroupRecords])

  useEffect(() => {
    if (childSubmissions) {
      setSubmissions(childSubmissions)
    }
    if (groupRecords) {
      setSubmissions(groupRecords)
    }
  }, [childSubmissions, groupRecords])

  const [isList, toggleLayout] = useToggle(false)

  const isEmpty = (): boolean =>
    submissions?.APPROVED.length === 0 &&
    submissions?.REJECTED.length === 0 &&
    submissions?.IN_REVIEW.length === 0

  const itemsLayout: ItemLayout = isList ? 'list_view' : 'card_view'

  const listClassName = cn(
    'my-records__grid',
    !isList && 'my-records__grid--card-view',
  )

  return (
    <Screen>
      <BorderedContainer>
        <div className="w-100 flex items-center justify-between pb2">
          <Title className="pb0 pr2">My records</Title>
          <ViewSelector onClick={toggleLayout} view={itemsLayout} />
        </div>
        <section>
          <Conditional shouldRender={isLoading || isFetching}>
            <div className="w-100 flex justify-center pt4">
              <Loader
                style={{
                  width: '45px',
                  height: '45px',
                }}
                active
              />
            </div>
          </Conditional>
          {isEmpty() && (
            <EmptySection
              className="mt3"
              message="You don't have records submitted yet."
            />
          )}
          {submissions &&
            submissionsByStatus.map((status) => (
              <>
                {submissions[status].length > 0 && (
                  <Fragment key={status}>
                    <h2
                      className={cn(
                        'f4 pt4 pb4 ma0 nunito-regular',
                        getSubmissionStatusColor(status),
                      )}
                      key={status}
                    >
                      {capitalizeAndRemoveUnderscode(status)}
                    </h2>
                    <div className={listClassName}>
                      {submissions[status].map((submission) => (
                        <SubmissionCard
                          key={submission.id}
                          layoutType={itemsLayout}
                          submission={submission}
                        />
                      ))}
                    </div>
                    {/*{isLoading && showMoreStatus === status && (*/}
                    {/*  <div className="w-100 flex justify-center pt4">*/}
                    {/*    <Loader*/}
                    {/*      style={{*/}
                    {/*        width: '45px',*/}
                    {/*        height: '45px',*/}
                    {/*      }}*/}
                    {/*      active*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    {/*{!isLoading && page.hasNextPage && (*/}
                    {/*  <div className="w-100 flex justify-center pt4">*/}
                    {/*    <Button*/}
                    {/*      className="w-100 w4-l"*/}
                    {/*      ghost*/}
                    {/*      onClick={() => handleShowMore(status, page.endCursor)}*/}
                    {/*    >*/}
                    {/*      SHOW MORE*/}
                    {/*    </Button>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </Fragment>
                )}
              </>
            ))}
        </section>
      </BorderedContainer>
    </Screen>
  )
}

export default MyRecords
