import React from 'react'

import Button from 'components/Button'
import ShadowModal from 'components/ShadowModal'
import Conditional from 'components/Conditional'

import {
  useGetLegacyRelativeAccountsQuery,
  useGetMyLegacyProfileQuery,
} from 'store/api'
import { Record, RecordType } from 'store/api/types/Record.types'
import Profile from 'data/Profile'
import { PublicRecord } from 'hooks/useRecordApi.hook'

import { getUserAge } from './BreakRecordRestriction.utils'

import styles from './BreakRecordRestriction.module.scss'

interface BreakRecordRestrictionProps {
  isOpen?: boolean
  onClose: () => void
  record?: PublicRecord | Record
}

const BreakRecordRestriction = ({
  isOpen = false,
  onClose,
  record,
}: BreakRecordRestrictionProps) => {
  const { data: loggedProfile } = useGetMyLegacyProfileQuery()
  const { data: relativeAccounts = [] } = useGetLegacyRelativeAccountsQuery()

  const isChild = Profile.isChildProfile(loggedProfile)
  const isParent = Profile.isParentProfile(loggedProfile)
  const isGrownUp = isChild && getUserAge(loggedProfile) >= 17
  const isGroupRecord = record?.type === RecordType.GROUP

  const hasCorrectChildProfile = !!relativeAccounts.find(
    (child: any) =>
      child.ageBracket === record?.ageGap &&
      child.gender === record?.gender.toLowerCase(),
  )

  return (
    <ShadowModal isModalOpen={isOpen} noSize onCancel={onClose}>
      <h2 className={styles.title}>Record Restriction</h2>
      <Conditional shouldRender={!!loggedProfile}>
        <Conditional shouldRender={isGroupRecord && !isParent}>
          <span className={styles.text}>
            You must be logged in as a Parent/Teacher to break group records.
          </span>
        </Conditional>
        <Conditional shouldRender={!isGrownUp && isChild && !isGroupRecord}>
          <span className={styles.text}>
            You can't break this record because it doesn't match your Age or
            Gender group.
          </span>
        </Conditional>
        <Conditional shouldRender={isGrownUp && !isGroupRecord}>
          <span className={styles.text}>
            You’re all grown up, only kids between 4-16 can submit record
            attempts. But don’t worry, you will always be a member of our family
            of extraordinary individuals.
          </span>
        </Conditional>
        <Conditional shouldRender={isParent && hasCorrectChildProfile}>
          <span className={styles.text1}>
            To break this record switch to your kids profile by clicking the
            hippo icon in the top right corner.
          </span>
        </Conditional>
        <Conditional shouldRender={isParent && !hasCorrectChildProfile}>
          <span className={styles.text1}>
            Your kid must be in the same Age and Gender group as the current
            record holder to break this record.
          </span>
        </Conditional>
      </Conditional>
      <Conditional shouldRender={!loggedProfile}>
        <span className={styles.text}>
          Please register or log in to set or break a Kids World Record.
        </span>
      </Conditional>
      <Button
        onClick={(e) => {
          e.preventDefault()
          onClose()
        }}
      >
        OK
      </Button>
    </ShadowModal>
  )
}

export default BreakRecordRestriction
