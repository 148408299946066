import React, { useEffect } from 'react'
import { Navigate } from 'react-router'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useNavigate } from 'react-router-dom'

import BorderedContainer from 'components/BorderedContainer'
import LinkButton from 'components/LinkButton'
import SubmitButton from 'components/SubmitButton'
import Title from 'components/Title'
import { Page } from 'components/Page'
import { Input } from 'components/Inputs'
import { FormInput } from 'components/Form/FormInput'
import { FormDatePicker } from 'components/Form/FormDatePicker'

import {
  useGetMyLegacyProfileQuery,
  useUpdateParentProfileMutation,
} from 'store/api'
import { ParentUpdateBody } from 'store/api/types/Parent.types'

import {
  buildDefaultValue,
  PARENT_UPDATE_VALIDATOR,
} from './ParentProfileEdit.utils'
import Profiles from 'data/Profile'

const EditProfile: React.FC = () => {
  const navigate = useNavigate()
  const { data: profile } = useGetMyLegacyProfileQuery()
  const [updateParent, { isLoading }] = useUpdateParentProfileMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ParentUpdateBody>({
    resolver: joiResolver(PARENT_UPDATE_VALIDATOR),
    mode: 'all',
  })
  const isAdminLogged = Profiles.isAdminProfile(profile)

  const onSubmit = async (body: ParentUpdateBody) => {
    if (profile) {
      await updateParent({ id: profile.id, ...body }).unwrap()
      navigate(-1)
    }
  }

  useEffect(() => {
    if (profile) {
      reset(buildDefaultValue(profile))
    }
  }, [reset, profile])

  if (isAdminLogged) {
    return <Navigate to="/" />
  }

  return (
    <Page>
      <BorderedContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>Edit My Profile</Title>
          <Input label="Email" disabled value={profile?.email || ''} />
          <div className="flex flex-row">
            <div className="mr3 flex-auto">
              <FormInput
                name="firstName"
                label="First Name"
                control={control}
                errorText={errors.firstName?.message}
              />
            </div>
            <div className="ml3 flex-auto">
              <FormInput
                name="lastName"
                label="Last Name"
                control={control}
                errorText={errors.lastName?.message}
              />
            </div>
          </div>
          <FormDatePicker
            name="dateOfBirth"
            maxDate={dayjs().add(-25, 'years').toDate()}
            label="Date of Birth"
            control={control}
          />
          <div className="flex h-100 mv4 flex-column items-center justify-end justify-start-l mt5-l">
            <SubmitButton className="w-100 w5-l" disabled={isLoading}>
              Confirm
            </SubmitButton>
            <LinkButton
              disabled={isLoading}
              className="w-100 w5-l pt3"
              ghost
              to="/profile"
            >
              Cancel
            </LinkButton>
          </div>
        </form>
      </BorderedContainer>
    </Page>
  )
}

export default EditProfile
