import React, { useCallback, useState, memo } from 'react'
import cn from 'classnames'
import axios from 'axios'

import { CONFIG } from 'config'

import Records from 'data/Record'
import { Record, Submission } from 'store/api/types/Record.types'

import BuyContainer from 'components/BuyContainer'
import LikeButton from 'components/LikeButton'
import Link from 'components/Link'
import Button from 'components/Button'
import ImageWithPlaceHolder from 'components/ImageWithPlaceholder'

import { ItemLayout } from 'typings'

import { isImageFile } from 'utils/url'

import './card.css'
import Conditional from '../Conditional'

import BreakRecordRestriction from '../BreakRecordRestriction'
import ChildProfileData, { ChildProfile } from '../../data/ChildProfile'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import { PriorityChange } from './components/Buttons/PriorityChange'
import { getAuthUserToken } from '../../services/authentication'
import { AmbassadorBadge } from '../AmbassadorBadge'
import { getGenderLabel } from '../RecordSubmissionTable/RecordSubmissionTable'
import { useNavigate } from 'react-router-dom'
import {
  useGetEventParticipantInfoQuery,
  useGetMyLegacyProfileQuery,
} from 'store/api'
import { Product } from '../../store/api/types/Product.types'
import Profiles from 'data/Profile'

interface CardProps {
  description: string
  imageSrc: string
  info: string
  layoutType: ItemLayout
  link: string
  listViewClassName?: string
  title: string
  children?: any
  onProductClick?: any
  level?: number
}

const Card: React.FC<CardProps> = ({
  children,
  description,
  imageSrc,
  info,
  layoutType,
  link,
  listViewClassName,
  title,
  level,
}) => {
  const isListView = layoutType === 'list_view'

  const cardClassName = cn(
    'card overflow-hidden bg-white flex flex-column justify-between mw8',
    isListView && 'card--list-view',
  )

  const imageClassName = cn('card__media', isListView && 'small')

  const contentClassName = cn(
    'card__container flex flex-column',
    isListView ? 'justify-center pa0 ml3 pr2 small' : 'pa3 pb0',
  )

  const badgeClassName = cn('level_badge', isListView && 'small')

  return (
    <Link className=".pointer:hover" to={link}>
      <div className={cn(cardClassName, listViewClassName)}>
        <div className="flex relative w-100 card__image">
          <ImageWithPlaceHolder
            src={imageSrc}
            alt="Image representing the record"
            className={imageClassName}
            ariaHidden={true}
          />
          <Conditional shouldRender={Boolean(level)}>
            <AmbassadorBadge className={badgeClassName} level={level} />
          </Conditional>
        </div>
        <div className={contentClassName}>
          <p className="card__category dark-gray mb0">{info}</p>
          <h2 className="card__title overflow-hidden nunito-bold indigo-blue mt0 mb2">
            {title}
          </h2>
          <p className="card__description f6 overflow-hidden dark-gray mt0 mb2">
            {description}
          </p>
        </div>
        {children}
      </div>
    </Link>
  )
}

const MemoCard = memo(Card)

interface RecordCardProps {
  record: Record
  layoutType: ItemLayout
  isAdminLogged: boolean | null
  isLiked: boolean
  onLike: (record: Record) => void
}

const getImage = (links: string[]): string | undefined => {
  return links.find((img) => isImageFile(img))
}

export const RecordCard = ({
  record,
  layoutType,
  isAdminLogged,
  isLiked,
  onLike,
}: RecordCardProps): JSX.Element => {
  const { data: profile } = useGetMyLegacyProfileQuery()
  const navigate = useNavigate()
  const [recordPriority, setRecordPriority] = useState(record.priority || 0)
  const [isRestricted, setIsRestricted] = useState(false)
  const isListView = layoutType === 'list_view'
  const isCardView = !isListView

  const likeButtonClassName = cn(
    'card__buttons flex justify-between',
    isListView ? 'small ph2 items-start' : 'pa3 pt0 items-center',
  )

  const breakButtonClassName = record.quizId
    ? 'card__beat-quiz-button'
    : 'card__break-record-button'

  let age =
    new Date(
      new Date().getTime() - new Date(profile?.dateOfBirth || 0).getTime(),
    ).getFullYear() - 1970
  // cur.getFullYear() - new Date(profile?.dateOfBirth || 0).getFullYear() // This is the difference in milliseconds
  const ageBracket = record.ageGap.split('-').map((i) => parseInt(i))

  const onPriorityChange = async (e: any) => {
    try {
      e.preventDefault()
      const token = getAuthUserToken()
      await axios.patch(
        `${CONFIG.FUNCTION_URL}/admin/record/${record.id}/priority`,
        { priority: recordPriority === 1 ? 0 : 1 },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      )
      setRecordPriority(recordPriority === 1 ? 0 : 1)
    } catch (e) {}
  }

  const getRecordInfoDescription = (record: Record) => {
    const gender = ChildProfileData.getGender(record.gender)
    const address = `${record.location.city}, ${
      record.location.state === 'N/A' ? '' : record.location.state
    } (${record.location.country})`
    return `${gender} - ${record.ageGap} YEARS OLD - ${address}`.toUpperCase()
  }

  const likeContainer = (
    <div className={likeButtonClassName}>
      <Conditional shouldRender={isCardView}>
        <div
          className="card__record-button-container"
          style={{ height: '42px' }}
        >
          <Button
            className={breakButtonClassName}
            disabled={!!record.deletedAt || record.newRecordId}
            onClick={useCallback(
              (e) => {
                e.preventDefault()
                if (
                  age >= ageBracket[0] &&
                  age <= ageBracket[1] &&
                  (profile as ChildProfile)?.gender === record.gender
                ) {
                  navigate(
                    record.quizId
                      ? `/quiz/${record.quizId}`
                      : `/break-a-record/${record.idea.id}/${record.id}`,
                  )
                } else if (
                  Profiles.isParentProfile(profile) &&
                  record.type === 'GROUP'
                ) {
                  navigate(`/group-submission/${record.id}`)
                } else {
                  setIsRestricted(true)
                }
              },
              // eslint-disable-next-line
              [profile],
            )}
            endIcon={
              record?.noNewRecord === !record?.noNewRecord ? (
                <BlockRoundedIcon style={{ color: 'var(--neo-red)' }} />
              ) : (
                <img
                  src="/assets/trophy.svg"
                  alt="a trophy representing the record to be claimed"
                />
              )
            }
          >
            {record.deletedAt || record.newRecordId
              ? 'Record Broken'
              : record.quizId
                ? 'BEAT THIS QUIZ'
                : 'BREAK THIS RECORD'}
          </Button>
        </div>
      </Conditional>
      <Conditional shouldRender={!isAdminLogged}>
        <LikeButton
          isSimpleView={isListView}
          isLiked={isLiked}
          onClick={(e) => {
            e.preventDefault()
            onLike(record)
          }}
          likes={record.likes}
        />
      </Conditional>
      <Conditional shouldRender={!!isAdminLogged}>
        <PriorityChange
          priority={recordPriority}
          onChangePriority={onPriorityChange}
        />
      </Conditional>
    </div>
  )

  return (
    <>
      <MemoCard
        description={record.description}
        imageSrc={getImage(record.mediaLinks) || ''}
        info={getRecordInfoDescription(record)}
        layoutType={layoutType}
        link={`/records/${record.id}`}
        listViewClassName="card-record"
        title={record.idea.title}
        level={record.ambassadorLevel}
      >
        {likeContainer}
      </MemoCard>
      <BreakRecordRestriction
        isOpen={isRestricted}
        onClose={() => setIsRestricted(false)}
        record={record}
      />
    </>
  )
}

export const SubmissionCard: React.FC<{
  layoutType: ItemLayout
  submission: Submission
}> = ({ layoutType, submission }) => {
  const getInfoDescription = (submission: Submission) => {
    const parsedGender = getGenderLabel(submission.gender)
    const address = Records.getAddressForRecordCard(submission.location)
    const baseInfoDescription =
      submission.type === 'GROUP'
        ? `${submission.category} GROUP RECORD`
        : `${parsedGender} - ${submission.ageGap} YEARS OLD`
    return `${baseInfoDescription} - ${address}`.toUpperCase()
  }

  return (
    <Card
      description={submission.description}
      imageSrc={submission.previewImageUrl}
      info={getInfoDescription(submission)}
      layoutType={layoutType}
      link={`/my-records/${submission.id}`}
      listViewClassName="card-submission"
      title={submission.idea}
    />
  )
}

const getProductId = (slugId: string) => {
  const spl = slugId.split('/')
  return spl[spl.length - 1]
}

export const ProductCard: React.FC<{
  product: Product
  isLogged: boolean
  token: string
}> = ({ product, isLogged, token }) => {
  const { data: eventParticipants = [] } = useGetEventParticipantInfoQuery(
    token,
    { skip: !token },
  )

  const disabled = !isLogged && product.isCustom && !eventParticipants.length

  return (
    <Card
      description={product.description}
      imageSrc={product.previewImageUrl}
      info={product.info}
      layoutType={'card_view'}
      link={
        disabled
          ? '/shop'
          : eventParticipants.length
            ? `/shop/${getProductId(product.id)}?token=${token}`
            : `/shop/${getProductId(product.id)}`
      }
      listViewClassName="card-submission"
      title={product.title}
      children={<BuyContainer product={product} disabled={disabled} />}
    />
  )
}

export default memo(RecordCard)
