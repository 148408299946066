import React, { FC, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import {
  useGetEventParticipantInfoQuery,
  useGetChildrenRecordsQuery,
} from 'store/api'
import { ProductDetails } from './ProductDetails'
import { AccountRole } from 'store/api/types/Auth.types'

export const ProductDetailsPage: FC = () => {
  const url = new URLSearchParams(useLocation().search)
  const token = url.get('token') || ''
  const { data: eventParticipants = [] } = useGetEventParticipantInfoQuery(
    token,
    { skip: !token },
  )

  const { data: childrenWithRecords = [] } = useGetChildrenRecordsQuery()

  const childrenApprovedRecords = useMemo(() => {
    if (eventParticipants.length) {
      return eventParticipants.map((participant) => ({
        role: AccountRole.CHILD,
        childName: `${participant.kidsFirstName} ${participant.kidsLastName}`,
        records: [
          { id: participant.id, title: participant.eventTitle, url: `` },
        ],
      }))
    }

    if (childrenWithRecords.length) {
      return childrenWithRecords.map((item) => ({
        role:
          item.child.parentId.length !== 0
            ? AccountRole.CHILD
            : AccountRole.PARENT,
        childName: `${item.child.firstName} ${item.child.lastName}`,
        records: item.records.map((record) => ({
          id: record.id,
          title: record.idea,
          url: `https://kidsworldrecords.com/records/${record.id}`,
        })),
      }))
    }
    return []
  }, [eventParticipants, childrenWithRecords])

  return <ProductDetails childrenApprovedRecords={childrenApprovedRecords} />
}
